import { useCallback, useEffect, useRef } from "react"
import { useInView } from "react-intersection-observer"

import Config from "@spatialsys/web/config"
import { cn } from "@spatialsys/web/ui"

type MarketingVideoProps = {
  className?: string
  posterPath?: string
  videoPath: string
  webmVideoPath?: string
}

export function MarketingVideo({ className, posterPath, videoPath, webmVideoPath }: MarketingVideoProps) {
  const videoRef = useRef<HTMLVideoElement | null>(null)
  const { ref: inViewRef, inView } = useInView()
  const setRef = useCallback(
    (node: HTMLVideoElement) => {
      videoRef.current = node
      inViewRef(node)
    },
    [inViewRef]
  )

  /** Only play the video if the video component is in the viewport */
  useEffect(() => {
    /** Check if the video element is defined */
    inView ? videoRef.current?.play() : videoRef.current?.pause()
  }, [inView])

  return (
    <video
      ref={setRef}
      className={cn("h-full w-full object-cover", className)}
      disablePictureInPicture
      disableRemotePlayback
      loop
      muted
      playsInline
      poster={posterPath ? `${Config.PUBLIC_ASSETS_BASE_URL}/${posterPath}` : undefined}
      /**
       * Minimize data that is preloaded. We only prefetch the metadata which includes the thumbnail
       */
      preload="metadata"
    >
      {webmVideoPath && <source type="video/webm" src={`${Config.PUBLIC_ASSETS_BASE_URL}/${webmVideoPath}`} />}
      <source type="video/mp4" src={`${Config.PUBLIC_ASSETS_BASE_URL}/${videoPath}`} />
    </video>
  )
}
