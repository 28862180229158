import Link from "next/link"
import { memo } from "react"
import { Balancer } from "react-wrap-balancer"

import { ReactComponent as ArrowRightIcon } from "@spatialsys/assets/icons/lucide/arrow-right.svg"
import { TrackedComponent, TrackedComponents } from "@spatialsys/react/analytics"
import { MarketingVideo } from "@spatialsys/web/core/js/components/marketing-section/marketing-video/marketing-video"
import { Button, Container, Heading, Text } from "@spatialsys/web/ui"

export const FreeOnlineGames = memo(function FreeOnlineGames() {
  return (
    <TrackedComponent id={TrackedComponents.FreeOnlineGames} as="div">
      <Container className="grid items-center gap-4 xs:grid-cols-2">
        <div className="mx-4 grid gap-4 md:gap-6 lg:mx-6 xl:mx-8">
          <Balancer>
            <Heading className="text-m3 lg:text-m2" weight="black" asChild>
              <h1>Free Online Games</h1>
            </Heading>
          </Balancer>
          <Text className="md:text-md max-w-[900px] text-sm text-muted-foreground lg:text-lg">
            Spatial is the home for the most fun collection of free online games. Play our beautifully crafted 3D games
            on desktop, mobile, tablet, and even in VR. Just click to jump straight into cool games with friends from
            all around the world. No download required.
          </Text>
          <Button
            as={Link}
            href="/about"
            className="w-fit"
            color="white"
            size="md"
            rightIcon={<ArrowRightIcon className="icon icon-xs" />}
          >
            Learn More
          </Button>
        </div>

        <MarketingVideo
          className="row-start-1 h-auto w-full px-2 xs:row-auto xs:px-1 lg:px-6"
          posterPath="about/cp-spatial-gaming.jpg"
          videoPath="about/cp-spatial-gaming.mp4"
          webmVideoPath="about/cp-spatial-gaming.webm"
        ></MarketingVideo>
      </Container>
    </TrackedComponent>
  )
})
